export const division: { [key: string]: string } = {

        "1": "Abu Dhabi",
        "2": "Ajman",
        "3": "Bahrain",
        "4": "Dubai",
        "5": "Fujairah",
        "6": "Gaza",
        "7": "Jordan",
        "8": "Kuwait",
        "9": "Oman",
        "10": "Qatar",
        "11": "Ras Al-Khaimah",
        "12": "KSA",
        "13": "Sharjah",
        "14": "Umm Al Quwain",
        "15": "Yemen"
    }

